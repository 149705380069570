import { rest } from 'lodash'

const he = {
  appTitle: 'נוגטין',
  generics: {
    bin: 'סל מחזור',
    restoreFromBin: 'שחזור מסל המחזור',
    restoreMessage: 'האם אתה בטוח שברצונך לשחזר',
    sum: 'סה"כ',
    quantity: 'כמות',
    toPage: 'לדף',
    item: 'פריט | פריטים',
    level: 'רמה',
    totalScore: 'סך כל הנקודות',
    score: 'ניקוד | נקודות',
    deleteThe: 'למחוק את',
    the: 'את',
    home: 'בית',
    mainMenu: 'תפריט ראשי',
    change: 'שנה',
    changes: 'שינויים',
    actions: 'פעולות',
    delete: 'למחוק',
    deleteThis: 'למחוק את',
    forceChanges: 'כפה שנויים',
    remove: 'מחיקה',
    name: 'שם',
    show: 'הצג',
    add: 'הוסף',
    save: 'שמור',
    edit: 'ערוך',
    toEdit: 'לערוך',
    search: 'חיפוש',
    searchByNameOrOrderNumber: 'חיפוש לפי שם או מספר הזמנה',
    new: 'חדש',
    cancel: 'ביטול',
    confirm: 'אישור',
    user: 'משתמש',
    users: 'משתמשים',
    selectColors: 'בחר צבע',
    color: 'צבע',
    escorters: 'מלווים',
    send: 'שלח',
    title: 'כותרת',
    message: 'הודעה',
    deleteAll: 'מחק הכל',
    outbox: 'דואר יוצא',
    urgent: 'דחוף',
    regular: 'רגיל',
    complex: 'מורכב',
    days: 'ימים',
    date: 'תאריך',
    hour: 'שעה',
    time: 'זמן',
    all: 'הכל',
    status: 'סטטוס',
    approve: 'מאשר',
    decline: 'דחה',
    reject: 'דחה',
    location: 'מיקום',
    before: 'לפני',
    addressee: 'נמען',
    join: 'הצטרפות',
    leave: 'יציאה',
    requestSent: 'בקשתך נשלחה',
    global: 'כללי',
    applicant: 'הפונה',
    type: 'סוג',
    escort: 'ליווי',
    sentAt: 'נשלח בתאריך',
    newEscortRequests: 'בקשת ליווי חדש',
    numberOfPeople: 'מספר נפשות',
    oneTime: 'חד פעמי',
    permanent: 'קבוע',
    supply: 'אספקה',
    orderDetails: 'פרטי הזמנה',
    notes: 'הערות',
    info: 'הערה',
    noNotes: 'אין הערות',
    numOfNotes: 'קיימות {num} הערות',
    range: 'טווח',
    address: 'כתובת',
    deliveries: 'משלוחים',
    selfPickUp: 'איסוף עצמי',
    image: 'תמונה',
    mobile: 'ח.פ/טל.',
    options: 'אפשרויות',
    save: 'שמירה',
    deferred: 'דחוי',
    cash: 'מיידי',
    ils: "ש''ח",
    department: 'מחלקה',
    price: 'מחיר',
    uploadImage: 'העלאת תמונה',
    catalogNumberText: "מק''ט",
    sent: 'נמסר',
    collectTime: 'שעת איסוף',
    notFound: '404 דף לא נמצא',
    product: 'מוצר',
    dailyView: 'תצוגה יומית',
    dateRange: 'טווח תאריכים',
    uploadExcel: 'העלאת קובץ אקסל',
    socialId: 'ת.ז',
    fromDate: 'מהתאריך',
    untilDate: 'עד התאריך',
    confirmDeliveryArrangement: 'אשר תיאום משלוח',
    departments: 'מחלקות',
    sum: 'סה"כ',
    including: 'כולל',
    vat: 'מע"מ',
    discount: 'הנחה',
    rounded: 'עיגול',
    moreDetails: 'מידע נוסף',
    noDate: 'תאריך לא נקבע',
    noDelivaryTime: 'לא נקבעה שעה',
    noAddress: 'לא נקבעה כתובת',
    addressForDelivery: 'כתובת למשלוח',
    notReadyFromKitchen: 'המוצר עוד לא מוכן מהמטבח',
    productName: 'שם מוצר',
    orderNum: 'הזמנה מספר',
    hours: 'שעות',
    noOrdersToday: 'אין הזמנות להיום',
    allDepartments: 'כל המחלקות',
    department2: 'מחלקת',
    suppyTimeToClient: 'שעת אספקה ללקוח',
    collectedBy: 'נאסף על ידי',
    editOrder: 'עריכת ארוע',
    links: 'קישורים',
    sendLinkForPayment: 'שליחת קישור לתשלום',
    downloadPdf: 'הורדת קובץ הזמנה',
    copyPayLink: 'העתקת קישור לתשלום',
    copyLink: 'העתקת קישור',
    inBox: 'בקרטון',
    boxNum: 'מספר קרטונים',
    backToCalendarDefault: 'חזרה לברירת מחדל',
    sumWithTax: 'סה"כ כולל מע"מ',
    infoOnPayment: 'הערות על התשלום',
    clientSign: 'חתימת לקוח',
    contact: 'איש קשר',
    sumToPay: 'סה"כ לתשלום',
    shareLinks: 'שיתוף קישור | שיתוף קישורים',
    saveAndDownload: 'שמירה והורדה',
    pickMenu: 'בחירת תפריט קיים',
    orderHasBeenCreated:
      'ההזמנה קיימת! לא ניתן ליצור שוב. לשמירת שינויים לחצו על שמירה',
    badDateChosen: 'בחרת תאריך לא תקין',
    filterBarAll: 'כל ההזמנות',
    filterBarAllBids: 'כל ההצעות',
    filterBarDates: 'טווח תאריכים',
    category: 'קטגוריה',
    subCategory: 'תת קטגוריה',
    internalNotes: 'הערות נוספות שהלקוח אינו רואה',
    notesOnMenu: 'הערות על התפריט (יוצג ללקוח)',
    internalNotesForTable: 'הערות נסתרות',
    notesOnMenuForTable: 'הערות על התפריט',
  },
  fileUpload: {
    upload: 'העלאה',
    dragFileHere: 'גררו את הקובץ לכאן',
  },
  days: [
    { name: 'ראשון', key: 'א' },
    { name: 'שני', key: 'ב' },
    { name: 'שלישי', key: 'ג' },
    { name: 'רביעי', key: 'ד' },
    { name: 'חמישי', key: 'ה' },
    { name: 'שישי', key: 'ו' },
    { name: 'שבת', key: 'ז' },
  ],
  date: {
    month: 'חודש',
    week: 'שבוע',
    day: 'יום',
    today: 'היום',
    dateStart: 'תאריך התחלה',
    dateEnd: 'תאריך סיום',
    supplyDate: 'תאריך אספקה',
    supplyStartHour: 'שעות אספקה',
    productionDate: 'תאריך הפקה',
    production: 'תאריך הפקה',
    creationDate: 'תאריך יצירה',
    deletionDate: 'תאריך מחיקה',
    timeStart: 'זמן התחלה',
    timeEnd: 'זמן סיום',
    minutes: 'דקות',
    monthsNames: [
      'ינואר',
      'פברואר',
      'מרץ',
      'אפריל',
      'מאי',
      'יוני',
      'יולי',
      'אוגוסט',
      'ספטמבר',
      'אוקטובר',
      'נובמבר',
      'דצמבר',
    ],
  },
  drawer: {
    home: 'דף הבית',
  },
  auth: {
    email: 'אימייל',
    password: 'סיסמה',
    login: 'התחברות',
    logout: 'התנתקות',
    fullNameMsg: 'נא להזין שם מלא',
    emailRequired: 'נא להזין אימייל',
    emailBad: 'אימייל לא תקין',
    badPassword: 'נא להזין סיסמה',
    shortPassword: '6 תווים לפחות',
  },
  bid: {
    noBids: 'אין הצעות מחיר',
    index: 'הצעת מחיר | הצעות מחיר',
    create: 'יצירת הצעת מחיר',
    edit: 'עריכת/אישור הצעה',
    delete: 'מחיקת הצעה',
    askDelete: "למחוק את ההזמנה '{name}'?",
    discountInPercent: 'הנחה באחוזים',
    discountRemainder: 'עיגול',
    priceAfterDiscount: 'סה"כ אחרי עיגול',
    customerSelection: 'בחירת לקוח',
    situation: 'מצב',
    approved: 'אושר',
    waitingForApprove: 'ממתין לאישור',
  },
  client: {
    index: 'לקוח | לקוחות',
    name: 'שם הלקוח',
    private: 'פרטי',
    business: 'עסקי',
    handlerTitle: 'ניהול לקוח',
    handlerDriverTitle: 'ניהול נהג',
    handlerTitleclients: 'ניהול לקוחות',
    handlerTitleDrivers: 'ניהול נהגים',
    company: 'חברה',
    userId: 'ח.פ.',
    city: 'עיר',
    street: 'רחוב',
    houseNum: 'מספר בית',
    postalCode: 'מיקוד',
    defaultPaymentMethod: 'שיטת תשלום',
    icount_id: 'מזהה לאייקאונט',
    deleteBlock: 'לא ניתן למחוק משתמש שיש לו הזמנות',
  },
  delivery: {
    workerName: 'שם השליח',
    index: 'משלוח',
    deliver: 'שליח',
    notes: 'הערות על המשלוח',
    servingType: 'צורת הגשה',
    contacts: 'הערות נוספות על משלוח',
    servingTypes: {
      ready: 'מוכן להגשה!',
      selfHeating: 'חימום עצמי',
      'same-day': 'הגשה מאוחרת!',
      readyForTomorrow: 'ההגשה למחרת!',
      tommorow: 'ההגשה למחרת!',
      'no-mention': 'ללא אזכור',
    },
    collectFromShopAddress: 'איסוף עצמי מרחוב הסיבים 4, פתח תקווה',
    deliveredTime: 'שעת מסירה ללקוח',
    shipperCollected: 'ההזמנה נאספה על ידי השליח',
    contact: 'איש קשר',
  },
  product: {
    isTitle: 'זו כותרת',
    orderProduct: 'פריט הזמנה',
    price: "מחיר ליח'",
    name: 'שם הפריט',
    title: 'ניהול מוצר',
    titlePlural: 'ניהול מוצרים',
  },
  userManagement: {
    name: 'שם',
    email: 'אימייל',
    phone: 'טל.',
    password: 'סיסמה',
    role: 'סוג משתמש',
    changePassword: 'שנה סיסמה',
    new: 'הוספת משתמש',
    edit: 'עריכת משתמש',
    createUserSuccessMessage: 'המשתמש נוסף בהצלחה',
    editUserSuccessMessage: 'המשתמש עודכן בהצלחה',
    changeUserPasswordWarning: 'הסיסמא של {name} עומדת להשתנות. אתה בטוח?',
    allUsers: 'כל המשתמשים',
  },
  userDetails: {
    userDetails: 'פרטים אישיים',
    name: 'שם',
    fullName: 'שם מלא',
    phone: 'טלפון',
    email: 'אימייל',
    id: 'מזהה',
  },
  errors: {
    validate: {
      complexPaths: 'אנא הכנס שם ולפחות 2 מסלולים',
      paths: 'אנא הכנס שם ולפחות 2 נקודות',
      required: 'שדה חובה',
      minlen6: 'מינימום 6 תוים',
      minlen2: 'מינימום 2 תוים',
      maxlen40: 'מקסימום 40 תוים',
      maxlen100: 'מקסימום 100 תוים',
      choose: 'נא לבחור',
      minAndMaxPassenger: 'בחר מינמום נוסע אחד ומקסימום {max} נוסעים',
    },
    auth: {
      email: 'יש להזין אמייל',
      emailInvalid: 'אימייל שגוי',
      phoneInvalid: 'טלפון שגוי',
      pass: 'יש להזין סיסמה',
      minlen6: 'מינימום 6 תוים',
    },
    notEmpty: 'לא יכול להיות ריק',
    global: 'אירעה שגיאה בלתי צפויה',
    globalShort: 'שגיאה',
    missingItems: 'פרטים חסרים',
    formInvalid: 'נא השלם את כל השדות',
  },
  order: {
    eraseAllMenus: 'מחיקת כל התפריטים',
    index: 'הזמנות',
    one: 'הזמנה',
    createOrder: 'צור הזמנה',
    noOrders: 'אין הזמנות קרובות',
    noOrdersShort: 'אין הזמנות קרובות',
    payed: 'שולם',
    notPayed: 'לא שולם',
    waiting: 'ממתין לתשלום',
    'in-progress': 'בהכנה',
    done: 'מוכן',
    delivered: 'ההזמנה נמסרה ללקוח',
    additionalInfo: 'הערות על שעת האספקה',
    address: 'כתובת למשלוח + הערות למשלוח',
    info: 'איש קשר להזמנה',
    infoAuthMsg: '(חובה למלא שדה זה כשמדובר בחברה)',
    generalNotesMenu: 'הערות נוספות',
    deliveryTime: 'שעת אספקה',
    paidForFrom: 'שולם עבור {paidQuantity} מתוך {totalQuantity}',
    num: 'מספר הזמנה',
    items: 'פריטי הזמנה',
    paymentMethod: 'צורת תשלום',
    deliveryTypes: 'אופן אספקה',
    delete: 'מחיקת ארוע',
    deleteOrderMsg: 'האם אתה בטוח שברצונך למחוק את ההזמנה',
    createNewMsg: 'האם ליצור הזמנה חדשה',
    created: 'הזמנה נוצרה בהצלחה',
    leaveMsg: `?אתה בטוח שברצונך לצאת
    שינויים לא ישמרו`,
    toDate: 'לתאריך',
    sumPay: "סה''כ לתשלום",
    receptionNumber: 'מספר קבלה / אישור תשלום',
    timeFetch: 'איסוף בשעה',
    fetchName: 'שם המקבל',
    approveDelivery: 'אישור מסירה',
    receiveTime: 'הזמנה נמסרה ל{receiver} ב {startHour}',
    allForProduct: 'כל ההזמנות לפריט',
    atTime: 'בשעה',
    main: 'מרכזת',
    changes: 'אירעו שינויים ב',
    storeSuccess: 'ההזמנה נוצרה בהצלחה',
    numOfBoxes: 'מספר קרטונים',
    includedDiscount: 'כולל {num}% הנחה',
    updateReceiver: 'עדכן שם המקבל',
    updateReceiverSignature: 'עדכן חתימת מקבל',
  },
  menu: {
    menu: 'תפריט | תפריטים',
    index: 'תפריט',
    saved: 'התפריט נשמר',
    save: 'שמירת תפריט לשימוש חוזר',
    saveName: 'עריכת שם התפריט',
    sumPrice: 'מחיר כולל',
    title: 'הצעת אירוח',
  },
  payment: {
    credit: 'אשראי',
    cash: 'מזומן',
    transfer: 'העברה בנקאית',
  },
  userTypes: {
    0: 'מנהל על',
    1: 'עובד מטבח',
    2: 'מנהל ייצור',
    3: 'חנות',
    5: 'לקוח עסקי',
    6: 'לקוח פרטי',
  },
  toasts: {
    update: 'העדכון הסתיים בהצלחה',
    success: 'הפעולה הסתיימה בהצלחה',
    destroy: 'המחיקה הסתיימה בהצלחה',
    store: 'היצירה הסתיימה בהצלחה',
  },
  collection: {
    index: 'ליקוט',
    collected: 'לוקטו',
    waiting: 'ממתין לליקוט',
    delivery: 'מועד אספקה',
    info: 'פרטי אספקה',
    address: 'כתובת אספקה',
  },
  departments: {
    1: 'קונדיטוריה',
    4: 'מטבח',
    7: 'כריכים',
    9: 'כללי',
    12: 'שילוח',
    bakery: '1',
    kitchen: '4',
    sandwiches: '7',
    shipping: '12',
    general: '9',
  },
  pdf: {
    download: 'הורדת מסמך',
  },
  cancelPolicy: {
    title: 'מדיניות ביטולים',
    text: `  ביטול עד 48 שעות לפני מועד המשלוח - ללא עלות (ביטול מלא או תיאום מחדש
      ללא עלות). ביטול עד השעה 10:00 בבוקר, ביום שלפני האספקה - יחויבו דמי
      ביטול בעלות של 5% מעלות ההזמנה. ביטול לאחר השעה 10:00 בבוקר, ביום שלפני
      האספקה - יבוצע חיוב מלא בעבור ההזמנה.`,
    companyName: 'נוגטין קונדיטוריה אחרת בע"מ',
    companyNameSecond: 'חנות מפעל',
    address: "רח' הסיבים 4, קריית מטלון פתח- תקווה",
  },
}
export default he
